<template>
  <v-container fluid class="pa-0 d-flex">
    <v-row class="justify-center align-center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-title>Connexion</v-card-title>
          <v-card-text class="mr-2">
            <v-text-field class="mx-4" data-login v-model="username" label="Nom d'utilisateur"></v-text-field>
            <v-text-field data-password class="mx-4" v-model="password" label="Mot de passe" :type="passwordInputType">
              <template v-slot:append-outer>
                <v-icon @click="switchPasswordVisibility">{{passwordVisibilityIcon}}</v-icon>
              </template>
            </v-text-field>
            <v-row class="px-2" justify="space-between" no-gutters>
              <v-col cols="3">
                <div class="align-content-start">
                  <v-checkbox label="Se souvenir de moi" v-model="rememberMe"></v-checkbox>
                </div>
              </v-col>
              <v-col class="pt-4" cols="3">
                <router-link to="/forgotpassword">
                  <span class="text-body-1 ma-0 mt-1">Mot de passe oublié</span>
                </router-link>
              </v-col>
              <v-col cols="3">
                <div class="align-content-end mt-1">
                  <v-btn data-connect @click="handleLogin" class="primary align-content-end">Se connecter</v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12">
                <p class="text-center body-1">
                  Bienvenue sur Optia, notre application conçue pour faciliter
                  le suivi de la maintenance de vos biens immobiliers et équipements. <br>
                  <a href="https://solutia.fr/" target="_blank">Plus d'informations</a>
                </p>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from "@/config";

export default {
  name: "Login",
  data() {
    return {
      "username": "",
      "password": "",
      "rememberMe": false,
      "showPassword": false,
    }
  },
  methods: {
    async handleLogin() {
      try {
        await this.$store.dispatch("users/login", {
          "username": this.username,
          "password": this.password,
          "rememberMe": this.rememberMe,
        });
        if(config.MODE === "production" && config.GA_CODE) {
          this.$gtag.event('optia-login', {
              'event_category': 'engagement',
              'event_label': this.username,
          });
        }
        if(this.$route.query.path !== undefined) {
          await this.$router.push(this.$route.query.path);
        } else {
          await this.$router.push("/")
        }
      } catch (e) {
          await this.$store.dispatch("messages/setAnnounce", {
            "type": "error",
            "message": "Ces identifiants ne nous ont pas permis de vous connecter."
          });
      }

    },
    switchPasswordVisibility() {
      this.showPassword = !this.showPassword;
    }
  },
  computed: {
    passwordVisibilityIcon() {
      return this.showPassword ? "mdi-eye-off" : "mdi-eye";
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    }
  }
}
</script>

<style scoped>

</style>